/* CardFlip.css */

.CardFlip-container {
  text-align: center;
  padding: 20px;
  font-family: 'Anton', monospace;
  color: #ffffff;
}

.CardFlip-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  margin-bottom: 20px;
}

.CardFlip-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
}

.CardFlip-card-type {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 4px;
}

.CardFlip-card {
  width: 200px;
  height: 300px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.8s ease-in-out;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgb(255, 255, 255), 0px 0px 30px rgba(255, 255, 255, 0.4);
}

.CardFlip-flipped {
  transform: rotateY(180deg);
}

.CardFlip-card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
}

.CardFlip-card-back {
  transform: rotateY(0deg);
}

.CardFlip-card-front {
  transform: rotateY(180deg);
}

.CardFlip-card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.CardFlip-card-middle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
}

.CardFlip-card-grade {
  font-size: 2.2rem;
  font-weight: bold;
  color: #ffffff;
  margin-top: 8px;
}

.CardFlip-arrow-icon {
  font-size: 2rem;
  color: #ccc;
  margin: 0 10px;
  opacity: 0.8;
}

.CardFlip-slider {
  width: 80%;
  margin-top: 20px;
  -webkit-appearance: none;
  outline: none;
  height: 20px;
  background: linear-gradient(90deg, rgba(60, 60, 60, 1) 0%, rgba(130, 130, 130, 1) 50%, rgba(60, 60, 60, 1) 100%);
  border-radius: 6px;
  box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.2), 0px 4px 8px rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
}

.CardFlip-slider::-webkit-slider-runnable-track {
  height: 20px;
  background: transparent;
  border-radius: 6px;
  cursor: pointer;
}

.CardFlip-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.6), 0px 4px 12px rgba(255, 255, 255, 0.3);
  margin-top: -1px;
}

.CardFlip-slider::-moz-range-thumb {
  width: 22px;
  height: 22px;
  background: linear-gradient(135deg, #555, #aaa);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.6), 0px 4px 12px rgba(255, 255, 255, 0.3);
}

.CardFlip-slider::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  pointer-events: none;
  border-radius: 6px;
}
