/* General Styling */
.features-page {
  display: flex;
  background: linear-gradient(to bottom, rgba(20, 50, 100, 1), rgba(0, 0, 0, 1));
  color: white;
  font-family: 'Anton', monospace;
  padding: 0;
  margin: 0;
  width: 100%;
}

.features-content {
  width: 70%;
  margin: 0 auto;
}

.features-sidebar {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.left-sidebar {
  margin-right: 5px;
}

.right-sidebar {
  margin-left: 5px;
}

.features-sidebar .features-sidebar-ad {
  margin-bottom: 20px;
  width: 100%;
}

.features-sidebar-ad {
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Title Styling */
.features-title {
  margin-top: 5%;
  font-size: 52px;
  text-align: center;
}

.features-title-underline {
  width: 70%;
  height: 4px;
  background-color: white;
  margin: 10px auto;
}

/* Keyframes for Pop In and Out animations */
@keyframes feature-pop-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes feature-pop-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

/* Apply pop animation only to message sections */
.feature-animate-pop {
  animation: feature-pop-in 0.6s ease forwards;
}

.feature-section:not(.feature-animate-pop) {
  animation: feature-pop-out 0.6s ease forwards;
}

/* Feature Block Styling */
.feature-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 20px; /* Increased spacing between sections */
}

/* Message Display Styling */
.feature-message-display {
  position: relative;
  max-width: 600px;
  padding: 20px;
  background: linear-gradient(to bottom right, rgba(0, 150, 255, 0.4), rgba(0, 150, 255, 0.2));
  border: 2px solid rgba(0, 150, 255, 0.8);
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 150, 255, 0.6), 0px 0px 25px rgba(0, 150, 255, 0.4);
  text-align: center;
  color: #fff;
  opacity: 1;
  backdrop-filter: blur(10px);
  margin: 0 auto;
}

.feature-message-display p {
  font-size: 18px;
}

.feature-message-display::before,
.feature-message-display::after {
  content: '';
  position: absolute;
  border-radius: 18px;
}

.feature-message-display::before {
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 1px solid rgba(0, 150, 255, 0.6);
}

.feature-message-display::after {
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 1px solid rgba(0, 150, 255, 0.4);
}

/* Header and Paragraph Styling */
.feature-header {
  font-size: 2.2rem;
  color: #ffffff;
  margin-bottom: 10px;
}

/* Image Row Styling */
.feature-images-row {
  display: flex;
  justify-content: center;
  gap: 20%;
  margin-top: 60px;
  align-items: center;
}

.feature-image {
  max-width: 20%;
  height: auto; /* Ensures each image retains its own height */
  object-fit: contain; /* Prevents distortion */
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 150, 255, 0.6), 0px 0px 25px rgba(0, 150, 255, 0.4);
  transition: transform 0.3s ease;
}

/* Hover effect */
.feature-block:hover .feature-image {
  transform: scale(1.05);
}

.grade-rows-container {
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(0, 0, 60, 0.8); /* Light blue background similar to Achievements */
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.3); /* Border with slight transparency */
  box-shadow: 0 0 10px rgba(0, 150, 255, 0.5); /* Glow effect */
  max-width: 600px;
  margin: auto;
  margin-bottom: 80px;
}

.grades-title {
  font-size: 2rem;
  color: white;
  margin-bottom: 5px;
}

.grades-underline {
  width: 50%;
  height: 4px;
  background-color: white;
  margin: 10px auto;
}

.grade-spinner-container {
  margin-top: 20px; /* Space between the underline and the spinner */
}

.achievement-cards-container {
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(0, 0, 60, 0.8); /* Light blue background */
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.3); /* Border with slight transparency */
  box-shadow: 0 0 10px rgba(0, 150, 255, 0.5); /* Glow effect */
  max-width: 800px;
  margin: auto;
  margin-bottom: 80px;
}

.achievement-title {
  font-size: 2rem;
  color: white;
}

.achievement-underline {
  width: 70%;
  height: 4px;
  background-color: white;
  margin: 10px auto;
}

.calculator-link {
  color: #3498db;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s;
  z-index: 10; /* Higher than other elements */
  position: relative; /* Ensures z-index is effective */
}

.calculator-link:hover {
  color: #1f78c1; /* Darker blue on hover */
}
