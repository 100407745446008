.exercise-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.exercise-modal-content {
    background: linear-gradient(145deg, #2b68a1, #3c7eb9);
    padding: 30px;
    border-radius: 15px;
    max-width: 450px;
    width: 100%;
    box-shadow: 0 6px 15px rgba(0, 150, 255, 0.6), 0 0 25px rgba(0, 150, 255, 0.4);
    text-align: center;
    position: relative;
    border: 2px solid rgba(255, 255, 255, 0.3);
}

.exercise-modal-title {
    font-size: 26px;
    color: white;
    font-family: 'Anton', sans-serif;
    margin-bottom: 20px;
    text-shadow: 0px 5px 10px rgba(0, 150, 255, 0.7);
}

.exercise-modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    color: white;
    font-size: 20px;
    border: none;
    cursor: pointer;
}

.exercise-modal-filters {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
    justify-content: center;
}

.exercise-modal-search-container {
    margin-bottom: 20px;
}

.exercise-modal-search {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    width: 80%;
    font-size: 16px;
}

.exercise-modal-dropdown {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.exercise-modal-list {
    list-style: none;
    padding: 0;
    margin-top: 15px;
    max-height: 250px;
    overflow-y: auto;
}

.exercise-modal-item {
    padding: 15px 20px;
    margin-bottom: 10px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1); /* Subtle background color to stand out */
    color: white;
    font-size: 18px;
    text-align: left;
    transition: background 0.3s, transform 0.2s;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2); /* Shadow effect to make it stand out */
    cursor: pointer;
}

.exercise-modal-item:hover {
    background: rgba(0, 150, 255, 0.4); /* Brighter hover background */
    transform: scale(1.05); /* Slight scaling on hover for emphasis */
    box-shadow: 0px 5px 10px rgba(0, 150, 255, 0.5); /* Stronger shadow on hover */
}
