.achievement-card-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(30, 60, 180, 0.8); /* Lighter blue background */
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 150, 255, 0.6); /* Glow effect */
    max-width: 700px;
    margin: auto;
    position: relative;
}

.cards-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.card {
    width: 150px; /* Smaller size for secondary cards */
    height: auto;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.card.main {
    width: 220px; /* Larger size for main card */
    transform: scale(1.1); /* Slightly bigger */
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8); /* Glow around main card */
}

.arrow-button {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 15px;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    width: 50px;
    height: 50px;
    position: absolute;
}

.arrow-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.arrow-button.left {
    left: -30px; /* Adjust spacing to center cards */
}

.arrow-button.right {
    right: -30px; /* Adjust spacing to center cards */
}
