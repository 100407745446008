.Calculator-outer-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    margin-top: 10px;
}

.calculator-sidebar-ad {
    width: 100%;
    height: 250px;
    margin-bottom: 20px;
    background-color: #f0f0f0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

.Calculator-sidebar {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 15%;
}

.Calculator-container {
    background: linear-gradient(145deg, #2b68a1, #3c7eb9);
    border-radius: 15px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    padding: 0px 25px 20px 25px;
    width: 300px;
    height: 500px;
    box-shadow: 0 0 20px rgba(0, 150, 255, 0.5), 0 0 30px rgba(0, 150, 255, 0.3);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures the button is at the bottom */
}

.Calculator-title {
    font-size: 28px;
    color: white;
    margin-bottom: 10px;
    font-family: 'Anton', monospace;
    text-shadow: 0px 4px 8px rgba(0, 150, 255, 0.6);
}

.Calculator-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    flex-grow: 1; /* Allows form to take up available space */
}

.Calculator-field {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    color: white;
}

.Calculator-bodyweight {
    display: flex;
    gap: 45px;
}

.Calculator-lift {
    display: flex;
    gap: 45px;
}

.Calculator-input,
.Calculator-unit {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f0f0f0;
    color: #333;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.2);
    outline: none;
    transition: all 0.3s ease;
}

.Calculator-button {
    background: linear-gradient(to right, #005a9c, #006bb3);
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 12px;
    border: 2px solid #0099ff;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 100, 200, 0.6), 0 0 15px rgba(0, 100, 200, 0.5);
    transition: all 0.3s ease;
    width: 100%;
}
  
  .Calculator-button:hover {
    background: linear-gradient(to right, #004080, #005a9c); /* Darker on hover */
    box-shadow: 0 6px 15px rgba(0, 100, 200, 0.8), 0 0 20px rgba(0, 100, 200, 0.7);
    transform: translateY(-2px);
    border-color: #00bfff;
  }
  