.ExerciseDetailsDisplay-container {
    width: 390px;
    background: linear-gradient(145deg, #3c2366, #2e1a4a); /* Darker purple gradient */
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 15px rgba(75, 0, 153, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', monospace;
    font-size: 18px;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    margin-top: -20px;
    padding: 15px;
}

/* Title styling with underline */
.ExerciseDetailsDisplay-title {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.ExerciseDetailsDisplay-title h3 {
    font-size: 24px;
    margin: 5px 0;
}

.underline {
    width: 80%; /* Adjust width as needed */
    height: 2px;
    background-color: white;
    margin: 5px auto 0;
    opacity: 0.8;
}

/* Progress bar styling */
.progress-bar {
    width: 100%;
    height: 12px;
    background-color: rgba(128, 128, 128, 0.3); /* Grey with opacity */
    border-radius: 10px;
    margin: 10px 0;
    position: relative;
}

.progress-bar-fill {
    background: linear-gradient(90deg, #7999ea, #2042ff); /* Sky blue gradient */
    height: 100%;
    border-radius: 10px;
    transition: width 0.3s ease;
    box-shadow: 0px 0px 15px white; /* Glow effect only on filled part */
}

/* Flex container for Prev and Next info */
.progress-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 5px;
}

.grade-info {
    text-align: center;
    flex: 1;
}

.grade-info.left {
    text-align: left;
}

.grade-info.right {
    text-align: right;
}

/* Ensures numbers appear right below the Prev and Next labels */
.grade-info span {
    display: block;
    font-size: 14px;
    opacity: 0.8;
}
