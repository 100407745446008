/* Footer.css */

.footer {
    width: 100%;
    background-color: #2b2b2b;
    color: #ffffff;
    text-align: center;
    padding: 20px;
  }
  
  .footer-content {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .footer-link {
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  