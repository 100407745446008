.grade-row {
    display: flex;
    align-items: center;
    background-color: rgba(23, 45, 68, 0.6);
    padding: 10px 15px;
    border-radius: 5px;
    margin: 20px 0;
    gap: 10px;
    box-shadow: 0 0 15px rgba(0, 150, 255, 0.5);
    position: relative;
    z-index: 0;
}

.grade-row-muscle-group {
    font-size: 1.2rem;
    color: white;
    font-weight: bold;
    width: 100px; /* Fixed width for alignment */
    text-align: left;
}

.grade-row-grade {
    width: 80px; /* Fixed width for alignment */
    text-align: center;
}

.rectangles-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px; /* Fixed width for alignment */
}

.rectangles-background {
    background-color: #121212;
    padding: 5px;
    display: flex;
    justify-content: center;
    width: 100%; /* Adjust to fit the container width */
}

.rectangles {
    display: flex;
    gap: 3px;
}

.rectangle {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border-radius: 1px;
}

.rectangle.filled {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.extra-rectangles-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 20px; /* Fixed width for alignment */
}

.mini-rectangle {
    position: absolute;
    width: 6px; /* Thinner than main rectangles */
    height: 20px; /* Same height as main rectangles */
    border-radius: 1px;
}

/* Glow effect for each mini rectangle */
.yellow-mini {
    background-color: yellow;
    left: 0;
    z-index: 1;
    box-shadow: 0 0 5px rgba(255, 255, 0, 0.6); /* Yellow glow */
}

.purple-mini {
    background-color: purple;
    left: 0; /* Overlap position with yellow */
    z-index: 2;
    box-shadow: 0 0 5px rgba(128, 0, 128, 0.6); /* Purple glow */
}

.red-mini {
    background-color: red;
    left: 0; /* Overlap position with purple and yellow */
    z-index: 3;
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.6); /* Red glow */
}
