.navbar {
  background: linear-gradient(to top, rgba(20, 0, 38, 1), rgba(0, 0, 0, 1));
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0;
  z-index: 1000;
  border-bottom: 3px solid rgba(60, 60, 60, 1);
  height: 10%;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  height: 100%;
}

.nav-logo {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}

.logo-image {
  width: 50px;
  height: 50px;
  margin-right: 5px;
}

.nav-logo h1 {
  font-size: 32px;
}

.nav-links {
  list-style-type: none;
  display: flex;
  gap: 20px;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-size: 22px;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

.nav-links li a:hover, 
.nav-links li a.active {
  background-color: rgba(60, 0, 115, 1);
  border-radius: 5px;
}

.nav-logo h1, 
.nav-links li a {
  font-family: "Anton", monospace;
}
