.gradient-text {
    width: 80px;
    font-weight: bold;
    font-size: 2.2rem;
    font-family: monospace;
    position: relative;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

/* Add the shadow and outline using a pseudo-element */
.gradient-text::before {
    content: attr(data-text); /* Use the same text content from data-text attribute */
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; /* Place outline behind gradient text, but in front of grade-row background */
    font-weight: bold;
    font-size: 2.2rem;
    font-family: monospace;
    width: 80px;
    
    /* Black traced border effect */
    text-shadow:
        -2px -2px 0 #000,  /* Top left */
        2px -2px 0 #000,   /* Top right */
        -2px 2px 0 #000,   /* Bottom left */
        2px 2px 0 #000,    /* Bottom right */

        /* White glow behind the text */
        0 0 5px rgba(255, 255, 255, 0.6),
        0 0 10px rgba(255, 255, 255, 0.5),
        0 0 15px rgba(255, 255, 255, 0.4);
}
