/* PrivacyPolicy.css */

.privacy-policy-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 30px;
    background: linear-gradient(145deg, #5e3baa, #47327a); /* Blueish-purple gradient */
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 20px rgba(0, 0, 255, 0.5), 0 0 30px rgba(75, 0, 153, 0.4); /* Glowing border */
    color: white;
    font-family: 'Anton', sans-serif;
    text-align: left;
  }
  
  .privacy-policy-title {
    font-size: 36px;
    color: #ffffff;
    text-align: center;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  
  .privacy-policy-intro {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .privacy-policy-section-title {
    font-size: 24px;
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: 10px;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  .privacy-policy-list {
    font-size: 16px;
    margin: 10px 0;
    line-height: 1.6;
    padding-left: 20px;
  }
  
  .privacy-policy-list li {
    margin-bottom: 15px;
    position: relative;
  }
  
  .privacy-policy-list li:before {
    content: "•";
    color: #00aaff;
    font-size: 20px;
    position: absolute;
    left: -20px;
  }
  
  .privacy-policy-list strong {
    color: #a0c4ff;
  }
  