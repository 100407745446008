/* General Styling */
.About-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.About-page {
  background: linear-gradient(to bottom, rgba(20, 50, 100, 1), rgba(0, 0, 0, 1));
  color: white;
  font-family: 'Anton', monospace;
  padding: 0;
  margin: 0;
  width: 100%;
}

/* Sidebar Styling */
.About-sidebar {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Space between ads */
}

.about-sidebar-ad {
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.about-sidebar-ad .adsbygoogle {
  width: 100%;
  height: auto;
}

/* Keyframes for Pop In and Out animations */
@keyframes About-pop-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes About-pop-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

/* Apply pop animation only to message sections */
.About-animate-pop {
  animation: About-pop-in 0.6s ease forwards;
}

.About-section:not(.About-animate-pop) {
  animation: About-pop-out 0.6s ease forwards;
}

.About-title{
  margin-top: 5%;
  font-size: 52px;
}

/* System Message Styling */
.About-system-message {
  position: relative;
  max-width: 600px;
  padding: 20px;
  margin: 5% auto 5%;
  background: linear-gradient(to bottom right, rgba(0, 150, 255, 0.4), rgba(0, 150, 255, 0.2));
  border: 2px solid rgba(0, 150, 255, 0.8);
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 150, 255, 0.6), 0px 0px 25px rgba(0, 150, 255, 0.4);
  text-align: center;
  color: #fff;
  font-size: 1.2rem;
  opacity: 1;
  backdrop-filter: blur(10px);
}

.HIW{
  margin: 5% auto 2%;
}

.About-system-message::before,
.About-system-message::after {
  content: '';
  position: absolute;
  border-radius: 18px;
}

.About-system-message::before {
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 1px solid rgba(0, 150, 255, 0.6);
}

.About-system-message::after {
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 1px solid rgba(0, 150, 255, 0.4);
}

/* Image Row Styling */
.About-image-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 10%;
}

/* Keyframes for Fade-Up Animation */
@keyframes About-fade-up-in {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes About-fade-up-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.About-image {
  width: 150px;
  opacity: 0;
  transition: opacity 0.6s ease, transform 0.6s ease;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 150, 255, 0.6), 0px 0px 25px rgba(0, 150, 255, 0.4); /* Glow effect */
}

.About-image-HIW {
  width: 300px;
  opacity: 1;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 150, 255, 0.6), 0px 0px 25px rgba(0, 150, 255, 0.4); /* Glow effect */
}

/* Fade-Up In and Out Animations for Images */
.About-image.About-image-fade-in {
  animation: About-fade-up-in 0.6s forwards;
}

.About-image:not(.About-image-fade-in) {
  animation: About-fade-up-out 0.6s backwards;
}

/* How It Works Title */
.About-how-it-works-heading {
  text-align: center;
  margin: 60px auto;
}

.About-how-it-works-title {
  font-size: 3rem;
  color: white;
  text-transform: uppercase;
  margin: 10px 10px;
}

.About-how-it-works-underline {
  width: 60%;
  height: 3px;
  background-color: rgba(0, 150, 255, 0.8);
  margin: 10px auto;
}

.About-title-underline {
  width: 70%;
  height: 4px;
  background-color: white;
  margin: 10px auto;
}

/* How It Works Sections */
.About-how-it-works-section {
  margin: 10% auto;
  margin-top: 0;
  text-align: center;
}
