.App {
  text-align: center;
  background: linear-gradient(to bottom, rgba(20, 50, 100, 1), rgba(0, 0, 0, 1), rgba(20, 50, 100, 1));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px; /* Adjust based on your navbar height */
}

.App-header {
  font-size: calc(10px + 2vmin);
}

.content {
  flex: 1; /* Makes content take the remaining space above the footer */
  padding-bottom: 60px; /* Add padding to avoid overlap with the fixed footer */
}