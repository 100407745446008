/* Layout Styling */
.Home-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: 'Anton', monospace;
}

.Home-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15%;
  padding-top: 20px;
}

.Home-main {
  width: 70%;
}

/* Ad Container Styling */
.ad-container {
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  background-color: #f0f0f0; /* Placeholder color */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Keyframes for Pop In and Out animations */
@keyframes Home-pop-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes Home-pop-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

.Home-animate-pop {
  animation: Home-pop-in 0.6s ease forwards;
}

.Home-system-message:not(.Home-animate-pop),
.Home-feature-image img:not(.Home-animate-pop) {
  animation: Home-pop-out 0.6s ease forwards;
}

/* General Styling */
.Home-homepage {
  background: linear-gradient(to bottom, rgba(20, 50, 100, 1), rgba(0, 0, 0, 1));
  color: white;
  font-family: 'Anton', monospace;
  padding: 0;
  margin: 0;
  width: 70%;
}

/* Hero Logo Styling */
.Home-hero-logo {
  max-width: 15%;
  height: auto;
}

.Home-JumpSpace {
  height: 100px;
}

.Home-hero {
  margin-top: 5%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Home-hero-title {
  font-size: 3rem;
  color: #fff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.Home-hero-subtext {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #ddd;
  max-width: 600px;
}

.Home-cta-button {
  margin-top: 30px;
  padding: 15px 30px;
  background-color: #3A1E71;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 10px;
  text-transform: uppercase;
  text-decoration: none;
}

.Home-cta-button:hover {
  background-color: #502D8C;
}

.Home-scrolling-feature {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 60px 20px;
  margin-bottom: 15%;
}

.Home-system-message {
  position: relative;
  max-width: 500px;
  padding: 20px;
  margin: 10px 70px;
  background: linear-gradient(to bottom right, rgba(0, 150, 255, 0.4), rgba(0, 150, 255, 0.2)); 
  border: 2px solid rgba(0, 150, 255, 0.8);
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 150, 255, 0.6), 0px 0px 25px rgba(0, 150, 255, 0.4); 
  text-align: center;
  color: #fff;
  font-size: 1.1rem;
  opacity: 0.9;
  backdrop-filter: blur(10px);
  transform: scale(0.8);
  opacity: 0;
}

.Home-system-message::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 1px solid rgba(0, 150, 255, 0.6);
  border-radius: 18px;
  pointer-events: none;
}

.Home-system-message::after {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 1px solid rgba(0, 150, 255, 0.4);
  border-radius: 21px;
  pointer-events: none;
}

.Home-system-header {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 10px;
  text-transform: uppercase;
  position: relative;
}

.Home-feature-image img {
  max-width: 40%;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 150, 255, 0.6), 0px 0px 25px rgba(0, 150, 255, 0.4);
  transform: scale(0.8);
  opacity: 0;
}

.Home-cta-section {
  padding-bottom: 40px;
  padding: auto 40px;
  text-align: center;
  margin-bottom: 30%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(to bottom right, rgba(0, 150, 255, 0.4), rgba(0, 150, 255, 0.2));
  border: 2px solid rgba(0, 150, 255, 0.8);
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 150, 255, 0.6), 0px 0px 25px rgba(0, 150, 255, 0.4);
  opacity: 0.9;
  backdrop-filter: blur(10px);
  transform: scale(0.8);
  animation: Home-pop-in 0.6s ease forwards;
}

.Home-cta-section h2 {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 20px;
}

.Home-cta-section::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 1px solid rgba(0, 150, 255, 0.6);
  border-radius: 18px;
  pointer-events: none;
}

.Home-cta-section::after {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 1px solid rgba(0, 150, 255, 0.4);
  border-radius: 21px;
  pointer-events: none;
}

.Home-cta-section .Home-cta-button {
  margin-top: 20px;
  padding: 15px 30px;
  background-color: #28A745;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 10px;
  text-transform: uppercase;
  text-decoration: none;
}

.Home-cta-section .Home-cta-button:hover {
  background-color: #218838;
}
