.grade-row-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin: auto;
    overflow: hidden;
    padding: 10px;
    
    /* Lighter blue background */
    background-color: rgba(30, 60, 120, 0.9); /* Adjusted to a lighter blue */

    /* Border with a glow effect */
    border: 2px solid rgba(173, 216, 230, 0.5); /* Light blue border */
    border-radius: 10px;
    
    /* Glowing shadow effect */
    box-shadow: 0 0 15px rgba(173, 216, 230, 0.6), 0 0 25px rgba(173, 216, 230, 0.4);
}

.grades-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.grade-row-wrapper {
    width: 100%;
    transition: transform 0.3s ease, opacity 0.3s ease; /* Smoother transition */
}

.grade-row-wrapper.small {
    transform: scale(0.85); /* Smaller for top/bottom rows */
    opacity: 0.5;
}

.grade-row-wrapper.main {
    transform: scale(1); /* Full size for main row */
    opacity: 1;
}
