.GradeResultDisplay-container {
  background: linear-gradient(145deg, #4169E1, #1E3A8A); /* Royal blue to darker royal blue gradient */
  border-radius: 15px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 20px rgba(0, 0, 80, 0.5), 0 0 30px rgba(0, 0, 139, 0.4); /* Darker blue shadows */
  width: 450px; /* Fixed width to match Calculator-container */
  height: 500px; /* Match Calculator-container height */
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-family: 'Anton', sans-serif;
  font-size: 24px;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  padding: 0px 25px 20px 25px;
}

.GradeResultDisplay-title {
  margin-top: 20px;
  font-size: 30px;
  color: white;
  text-align: center;
  font-family: 'Anton', sans-serif;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

.GradeResultDisplay-content {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
